<template>
  <b-row>
    <b-col sm="10" md="7" lg="7" class="">
      <div class="card px-lg-4 px-md-3 px-2 py-3">
        <div>
          <h5 class="">Choose Client</h5>
          <!-- <b-form-input id="client" placeholder="" /> -->
          <v-select v-model="chooseClient" label="name" :options="chooseClientOptions" />
        </div>
        <div class="mt-4">
          <h4 class="bold mb-2">Project Details</h4>

          <div class="d-flex mt-2">
            <h5 class="mr-2">Project Type</h5>
            <div class="d-flex">
              <b-form-radio
                v-model="selectCandidateType"
                name="expert"
                value="expert"
                class="mr-1"
              >
                Expert Search
              </b-form-radio>
              <b-form-radio
                v-model="selectCandidateType"
                name="executive"
                value="executive"
              >
                Executive Search
              </b-form-radio>
            </div>
          </div>
          <div class="mt-2">
            <h5 class="mt-1">Enter Title</h5>
            <b-form-input id="industry" placeholder="" />
          </div>
          <div class="mt-2">
            <h5 class="mt-1">Description</h5>
            <b-form-textarea
              id="textarea-rows"
              placeholder="Write Here..."
              rows="16"
            />
          </div>
          <b-row class="d-lg-flex mt-2 d-md-flex justify-content-between">
            <b-col lg="5" md="5" sm="12" class="2">
              <h5 class="mt-1">Location ( Country )</h5>
              <v-select
                id="country"
                v-model="country"
                label="title"
                :options="countries"
              />
            </b-col>
            <b-col lg="7" md="7" sm="12" class="pt-lg-3 pt-2 d-flex">
              <h5 class="mr-1">Is Remote working allowed ?</h5>
              <div class="d-flex">
                No
                <b-form-checkbox
                  class="ml-1"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                >
                  Yes
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
          <div class="d-lg-flex d-md-flex mt-2">
            <div class="w-lg-50 w-md-50 w-100 mr-2">
              <h5 class="mt-1">Number of Required Candidates.</h5>
              <b-form-input id="number" placeholder="" type="number" />
            </div>
            <div class="w-lg-50 w-md-50 w-100">
              <h5 class="mt-1">Tenure</h5>
              <b-form-datepicker
                v-model="selectTenure"
                selected-variant="primary"
                class=""
              />
            </div>
          </div>
          <div class="mt-2">
            <h5 class="mt-1">Industry</h5>
            <v-select
              v-model="selectIndustry"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="title"
              :options="industryOptions"
            />
          </div>
          <div class="mt-2">
            <h5 class="mt-1">Function</h5>
            <v-select
              v-model="selectFunction"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="title"
              :options="functionOptions"
            />
          </div>
          <div class="mt-2">
            <h5 class="mt-1">Sub Function</h5>
            <v-select
              v-model="selectSubFunction"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="title"
              :options="subFunctionOptions"
            />
          </div>
        </div>
      </div>
    </b-col>

    <b-col md="5" lg="5" class="pr-2 mt-0">
      <!--save draft publish btn - for medium and large device  -->
      <div class="card p-lg-3 p-2 d-none d-lg-block d-md-block">
        <div class="d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning"
            class="mr-2"
          >
            Save and Draft
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class=""
          >
            Save and Publish
          </b-button>
        </div>
      </div>
      <div class="card px-lg-3 px-2 pt-2 pb-3">
        <h4 class="bold py-1">Other Details</h4>
        <!--  <div>
            <h5 class="mt-1">Client Contact Name</h5>
            <b-form-input id="industry" placeholder="" />
          </div>
          <div>
            <h5 class="mt-1">Client Contact Designation</h5>
            <b-form-input id="industry" placeholder="" />
          </div>
          <div>
            <h5 class="mt-1">Client Contact Email</h5>
            <b-form-input id="industry" placeholder="" />
          </div>
          <div class="mt-1">
            <h5 class="">Client Contact Phone Number</h5>
            <b-form-input id="industry" placeholder="" />
          </div>
          <div class="mt-1">
            <h5 class="">Location ( Country )</h5>
            <b-form-input id="industry" placeholder="" />
          </div> -->
        <div class="mt-">
          <h6 class="mt-1">Client Contacts</h6>
          <div class="d-flex">
            <div v-for="(client, i) in clients.slice(0, 4)" :key="i" class="">
              <b-avatar :src="client.img" class="img" />
            </div>
            <!-- show all  -->
            <b-avatar text="AL" variant="light-secondary" class="" />
            <!-- add another  -->
            <b-avatar
              v-b-modal.modal-client-add
              :src="require('@/assets/images/icons/plus-circle@2x.png')"
              variant="light-secondary"
              class="ml-1"
            />
          </div>
        </div>
        <div class="mt-2">
          <h5 class="">Originator</h5>
          <div class="d-flex">
            <div
              v-for="(originator, i) in originators.slice(0, 4)"
              :key="i"
              class=""
            >
              <b-avatar :src="originator.img" class="img" />
            </div>
            <!-- show all -->
            <b-avatar text="AL" variant="light-secondary" class="" />
            <!-- add another -->
            <b-avatar
              v-b-modal.modal-add-originator
              :src="require('@/assets/images/icons/plus-circle@2x.png')"
              variant="light-secondary"
              class="ml-1"
            />
          </div>
        </div>
        <div class="mt-2">
          <h5 class="">Project Manager</h5>
          <div class="d-flex">
            <div
              v-for="(originator, i) in projectManagers.slice(0, 4)"
              :key="i"
              class=""
            >
              <b-avatar :src="originator.img" class="img" />
            </div>
            <!-- show all  -->
            <b-avatar text="AL" variant="light-secondary" class="" />
            <!-- add another -->
            <b-avatar
              v-b-modal.modal-add-project-manager
              :src="require('@/assets/images/icons/plus-circle@2x.png')"
              variant="light-secondary"
              class="ml-1"
            />
          </div>
        </div>
        <div class="mt-2">
          <h5 class="">Researcher</h5>
          <div class="d-flex">
            <div
              v-for="(originator, i) in researchers.slice(0, 4)"
              :key="i"
              class=""
            >
              <b-avatar :src="originator.img" class="img" />
            </div>
            <!-- show all  -->
            <b-avatar text="AL" variant="light-secondary" class="" />
            <!-- add another  -->
            <b-avatar
              v-b-modal.modal-add-researcher
              :src="require('@/assets/images/icons/plus-circle@2x.png')"
              variant="light-secondary"
              class="ml-1"
            />
          </div>
        </div>

        <div class="mt-4">
              <div>
                <h6>Attach Files</h6>
                <!-- <div v-for="(file, index) in " :key="file.name"> -->
             
                <div v-for="(file, index) in attachFiles" :key="file.name" class="d-flex justify-content-between align-items-center  p-1 mb-1 file_bg">
                  <h6 class=" my-auto">{{file.name}}</h6>
                  <b-img
                    @click="removeFile(index)"
                    class="text-white"
                    src="./../../assets/images/icons/crossIcon.png"
                  />
                </div>
              </div>
              <b-form-file
              v-model="attachFiles"
                multiple
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="handleAttachFiles"
              />
            </div>
        <div class="mt-2 ml-0 mr-0 mb-2">
          <h4 class="my-1 bold">Payment Structure</h4>
          <b-col class="border rounded-lg px-2 py-1">
            <div>
              <h6 class="mt-1">Hourly Pricing (Phone)</h6>
              <b-input-group prepend="$" class="input-group-merge">
                <b-form-input
                  id="hourlyPricingPhone"
                  v-model="hourlyPricingPhone"
                  placeholder=""
                />
              </b-input-group>
            </div>
            <div>
              <h6 class="mt-1">Hourly Pricing (Face to Face)</h6>
              <b-input-group prepend="$" class="input-group-merge">
                <b-form-input
                  id="hourlyPricingFace"
                  prepend="$"
                  v-model="hourlyPricingFace"
                  placeholder=""
                />
              </b-input-group>
            </div>
          </b-col>
        </div>
      </div>
    </b-col>
    <hr />
    <b-col cols="12" class="">
      <div class="card px-lg-3 px-2 pt-2 py-2">
        <h4 class="pt-1">Vetting Questions</h4>
        <b-form
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        ></b-form>
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <b-col cols="12" class="my-1">
            <div class="d-flex justify-content-between">
              <h5 class="">Question {{ index + 1 }}</h5>
              <div>
                <b-img
                  @click="removeItem(index)"
                  class="ml-2"
                  src="./../../assets/images/icons/Icon.png"
                />
              </div>
            </div>
            <b-form-textarea
              id="textarea-rows"
              placeholder="Write Here..."
              rows="3"
              cols="12"
            />
          </b-col>
        </b-row>

        <div @click="repeateAgain" class="my-2 d-flex align-items-center">
          <b-avatar
            :src="require('@/assets/images/icons/plus-circle@2x.png')"
            variant="light-secondary"
            class="mr-1"
          />
          <h5 class="my-0">Add Another Question</h5>
        </div>
      </div>
    </b-col>

    <!-- button for small device only  -->
    <b-col cols="11" class="d-lg-none d-md-none d-block card p-2 mx-auto">
      <div class="d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-warning"
          class="mr-2"
        >
          Save and Draft
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class=""
        >
          Save and Publish
        </b-button>
      </div>
    </b-col>

    <!-- modal for add client   -->
    <b-modal
      id="modal-client-add"
      centered
      title="Add Client"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      @ok=""
    >
      <div class="mx-lg-5 mx-md-3 mx-1 mb-3 mt-1">
        <div>
          <h5 class="mt-1">Client Contact Name</h5>
          <b-form-input id="industry" placeholder="" />
        </div>
        <div>
          <h5 class="mt-1">Client Contact Designation</h5>
          <b-form-input id="industry" placeholder="" />
        </div>
        <div>
          <h5 class="mt-1">Client Contact Email</h5>
          <b-form-input id="industry" placeholder="" />
        </div>
        <div class="mt-1">
          <h5 class="">Client Contact Phone Number</h5>
          <b-form-input id="industry" placeholder="" />
        </div>
        <div class="mt-1">
          <h5 class="">Location ( Country )</h5>
          <b-form-input id="industry" placeholder="" />
        </div>
      </div>
    </b-modal>

    <!-- modal for add originator   -->
    <b-modal
      id="modal-add-originator"
      centered
      title="Add Originator"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      @ok=""
    >
      <div class="mx-lg-5 mx-md-3 mx-1 mb-3 mt-1">
        <div>
          <h5 class="mt-1">Choose Client</h5>
          <v-select
            v-model="addOriginators"
            multiple
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="addOriginatorOptions"
            label="name"
          >
            <template #option="{ name, img }">
              <b-avatar :src="img" />
              <span class="ml-1"> {{ name }} </span>
            </template>
          </v-select>
        </div>
      </div>
    </b-modal>
    <!-- modal for add project manager   -->
    <b-modal
      id="modal-add-project-manager"
      centered
      title="Add Project Manager"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      @ok=""
    >
      <div class="mx-lg-5 mx-md-3 mx-1 mb-3 mt-1">
        <div>
          <h5 class="mt-1">Choose Client</h5>
          <v-select
            v-model="addProjectManagers"
            multiple
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="addProjectManagerOptions"
            label="name"
          >
            <template #option="{ name, img }">
              <b-avatar :src="img" />
              <span class="ml-1"> {{ name }} </span>
            </template>
          </v-select>
        </div>
      </div>
    </b-modal>

    <!-- modal for add researcher   -->
    <b-modal
      id="modal-add-researcher"
      centered
      title="Add Researcher"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      @ok=""
    >
      <div class="mx-lg-5 mx-md-3 mx-1 mb-3 mt-1">
        <div>
          <h5 class="mt-1">Choose Client</h5>
          <v-select
            v-model="addResearchers"
            multiple
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="addResearcherOptions"
            label="name"
          >
            <template #option="{ name, img }">
              <b-avatar :src="img" />
              <span class="ml-1"> {{ name }} </span>
            </template>
          </v-select>
        </div>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { email, required } from '@validations';
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BImg,
  BFormCheckbox,
  BFormDatepicker,
  BFormFile,
  BFormInput,
  BInputGroup,
  BFormRadio,
  BFormTextarea,
  BRow,
  BModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { VueAutosuggest } from 'vue-autosuggest';
export default {
  components: {
    BRow,
    BCard,
    BCardText,
    BCol,
    BModal,
    BImg,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BFormDatepicker,
    BButton,
    BAvatar,
    BFormFile,
    BInputGroup,
    vSelect,
    VueAutosuggest,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // suggested input for choose client
      chooseClient: null,
      chooseClientOptions: [{ name: 'Abdullah' }, { name: 'Fredly '}, {name:"Jhon"} ],
      // add question
      items: [
        {
          id: 1,
        },
      ],
      nextTodoId: 2,
      // modal
      addOriginators: [],
      addResearchers: [],
      addProjectManagers: [],
      // form

      resident: [],
      required,
      email,
      selectCountryCode: '',
      selectCandidateType: 'expert',
      hourlyPricingPhone: '',
      hourlyPricingFace: '',
      selectTenure: '',
      organization: '',
      attachFiles: [],
      selectIndustry: [],
      selectFunction: [],
      selectSubFunction: [],
      country: '',
      countries: [
        { title: 'India' },
        { title: 'America' },
        { title: 'Bangladesh' },
      ],
      industryOptions: [{ title: 'Option 01' }, { title: 'Option 02' }],
      functionOptions: [{ title: 'Option 01' }, { title: 'Option 02' }],
      subFunctionOptions: [{ title: 'Option 01' }, { title: 'Option 02' }],
      countryCodeOptions: [
        { value: '+61', text: 'Australia(+61)' },
        { value: '+689', text: 'French(+689)' },
      ],
      industryOptions: [{ title: 'Option 1' }, { title: 'Option 2' }],
      clients: [
        { img: `${require('@/assets/images/portrait/small/avatar-s-1.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-2.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-3.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-4.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-5.jpg')}` },
      ],
      originators: [
        { img: `${require('@/assets/images/portrait/small/avatar-s-1.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-2.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-3.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-4.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-5.jpg')}` },
      ],
      projectManagers: [
        { img: `${require('@/assets/images/portrait/small/avatar-s-6.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-7.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-8.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-9.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-10.jpg')}` },
      ],
      researchers: [
        { img: `${require('@/assets/images/portrait/small/avatar-s-11.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-12.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-13.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-14.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-15.jpg')}` },
      ],
      // modal info
      addResearcherOptions: [
        {
          name: 'Alexendar ',
          img: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
        },
        {
          name: 'Freedly Jhon',
          img: require('@/assets/images/portrait/small/avatar-s-15.jpg'),
        },
        {
          name: 'Saimon Roy',
          img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
        },
        {
          name: 'Farji  ',
          img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
        },
      ],
      addOriginatorOptions: [
        {
          name: 'Alexendar ',
          img: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
        },
        {
          name: 'Freedly Jhon',
          img: require('@/assets/images/portrait/small/avatar-s-15.jpg'),
        },
        {
          name: 'Saimon Roy',
          img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
        },
        {
          name: 'Farji  ',
          img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
        },
      ],
      addProjectManagerOptions: [
        {
          name: 'Alexendar ',
          img: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
        },
        {
          name: 'Freedly Jhon',
          img: require('@/assets/images/portrait/small/avatar-s-15.jpg'),
        },
        {
          name: 'Saimon Roy',
          img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
        },
        {
          name: 'Farbaj Khan ',
          img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
        },
      ],
    };
  },



  methods: {
// attach multiple files 
    handleAttachFiles(e){
      this.attachFiles= e.target.files
    },
    removeFile(index){
    this.attachFiles.splice(index, 1)
    },
    // form repeater

    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
.img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
}

.file_bg{
  background-color: #dcdcdc
}
</style>
